<template>
  <v-snackbar
    v-if="show"
    :value="show"
    :color="color"
    app
    outlined
    top
    center
    text
    elevation="24"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-icon
        :color="color"
        v-bind="attrs"
        @click="remove"
      >
        mdi-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Notification',
  data: () => ({
    colors: {
      error: 'red',
      success: 'green',
      info: 'blue',
    },
    types: [
      'error',
      'success',
      'info',
    ],
  }),
  methods: {
    ...mapActions('notification', [
      'reset',
    ]),
    remove() {
      this.reset();
    },
  },
  computed: {
    ...mapGetters('notification', [
      'notification',
    ]),
    message() {
      return this.notification?.message;
    },
    color() {
      return this.colors[this.notification.type];
    },
    show() {
      return !!this.notification;
    },
  },
};
</script>
